'use strict';

import { io } from 'socket.io-client';
import { marked } from 'marked';

// Inputs
const aiAssistButton = document.querySelector("[data-role='ai-assist-btn']");
const aiAssistContainer = document.querySelector("[data-role='ai-assist-container']");
const aiText = document.querySelector("[data-role='ai-text']");
const aiBtn = document.querySelector("[data-role='ai-button']");
const aiMessages = document.querySelector("[data-role='ai-messages']");


// Dynamically determine the server's URL
const protocol = window.location.protocol; // 'http:' or 'https:'
const host = window.location.hostname; // e.g., 'pingplayers.local', 'localhost', or your domain
const port = '2052'; // Define the port explicitly if required

// Construct the full server URL
const serverURL = `${protocol}//chat.${host}:${port}`;

// Set some defaults
let disabled = true;
let history = false;
let socket = null;
let threadId = "";
let socketContext = {}; // Context to send with our initial connection;

function SetActive(active) {
    if (!active) {
        disabled = true;
        aiBtn.disabled = true;
    } else {
        disabled = false;
        aiBtn.disabled = false;
    }
}
SetActive(false);

document.addEventListener("DOMContentLoaded", () => {
    if (aiText) {
        aiText.focus(); // Automatically focus when the DOM is ready
    }
});

// Toggle the AI Assistant
// The way it currently works is that it'll try and find a current thread, if that doesn't eixst it'll try and create a new one
if (aiAssistButton) {
    aiAssistButton.addEventListener('click', () => {
        if (aiAssistContainer.classList.contains("d-none")) {
            aiAssistContainer.classList.remove("d-none");
            InitializeSocket();
        } else {
            aiAssistContainer.classList.add("d-none");
        }
    });
} else {
    InitializeSocket();
}

// Fetch the thread ID
async function GetCurrentThread() {
    try {
        const response = await fetch('/api/ai/threads/get/currentthread');
        if (!response.ok) {
            throw new Error(`Failed to fetch thread ID: ${response.statusText}`);
        }

        const data = await response.json();
        return data.data.threadId;
    } catch (error) {
        console.error("Error fetching thread ID:", error);
        return null;
    }
}

async function NewThread() {
    try {
        const response = await fetch('/api/ai/threads/get/newthread');
        if (!response.ok) {
            throw new Error(`Failed to fetch thread ID: ${response.statusText}`);
        }

        const data = await response.json();
        return data.data.threadId;
    } catch (error) {
        console.error("Error creating new thread ID:", error);
        return null;
    }
}

// Initialize Socket.IO connection
async function InitializeSocket() {
    if (threadId.length > 0) {
        return; // Right now, we must return if the threadid is already generated as the socket has alread ybeen established
    } else {
        // Try get current thread
        // Failing that create a new one.
        threadId = await GetCurrentThread();
        if (!threadId) {
            threadId = await NewThread();
        }
    }
    if (!threadId) {
        aiText.disabled = true;
        aiText.placeholder = `Socket Error: No Thread ID`;
        console.error("Unable to connect: No thread ID available");
        return;
    }

    let lastAiReply;
    let markdownBuffer = ''; // Buffer to hold incomplete Markdown

    const urlParams = new URLSearchParams(window.location.search);
    const gameServerId = urlParams.get('gameServerId');
    socketContext.threadId = threadId;
    if (gameServerId) {
        socketContext.gameServerId = gameServerId;
    }

    const socket = io(serverURL, {
        query: { socketContext: JSON.stringify(socketContext) }
    });

    // Listen for when the agent is ready, and request any historic info.
    socket.on('agentReady', () => {
        if (!history) {
            history = true;
            socket.emit('loadHistory', () => {
                SetActive(true);
            });
        }
    });

    // Listen for complete message likely sent by historic data
    socket.on('message', (data) => {
        try {
            // Convert the Markdown chunk to HTML
            const html = marked(data.message);

            if (data.aiGenerated == "0") {
                ShowYourMessage(html);
            } else {
                ShowAIMessage(html);
            }
        } catch (error) {
            console.error('Error parsing Markdown in "message" event:', error);
        }
    });

    socket.on('streamStart', () => {
        markdownBuffer = "";

        ShowAIMessage("");

        // Select all elements with data-role="ai-reply"
        const aiReplies = document.querySelectorAll('[data-role="ai-reply"]');

        // Get the last one
        lastAiReply = aiReplies[aiReplies.length - 1];
    });

    socket.on('streamChunk', (data) => {
        markdownBuffer += data;
        lastAiReply.innerHTML = marked(markdownBuffer);
        ScrollMessageContainer();
    });

    socket.on('streamEnd', () => {
        ScrollMessageContainer();
        SetActive(true);
    });

    socket.on('error', (error) => {
        aiText.disabled = true;
        aiText.placeholder = `Socket error: ${error.message}`;
        console.error('Socket error:', error.message);
    });

    socket.on('disconnect', () => {
        console.log('Disconnected from the server');
    });

    // Handle sending messages
    if (aiBtn && aiText) {
        aiBtn.addEventListener('click', () => SendMessage(socket));
        aiText.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                SendMessage(socket);
            }
        });
    }

    function SendMessage() {
        if (!disabled) {
            const inputValue = aiText.value;

            socket.emit('message', inputValue, () => {
                ShowYourMessage(inputValue);
                aiText.value = "";
                SetActive(false);
            });
        }
    }

    function ShowYourMessage(message) {
        aiMessages.innerHTML += `
            <div class="d-flex my-4 ai-messages__user">
                ${message}
            </div>`;
        ScrollMessageContainer();
    }
    function ShowAIMessage(message) {
        aiMessages.innerHTML += `
            <div class="d-flex gap-3 align-start my-4">
                    <div class="ai-messages__assistant-avatar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M13.4326 3.49453C13.248 3.56426 13.125 3.74062 13.125 3.9375C13.125 4.13438 13.248 4.31074 13.4326 4.38047L15.75 5.25L16.6195 7.56738C16.6893 7.75195 16.8656 7.875 17.0625 7.875C17.2594 7.875 17.4357 7.75195 17.5055 7.56738L18.375 5.25L20.6924 4.38047C20.877 4.31074 21 4.13438 21 3.9375C21 3.74062 20.877 3.56426 20.6924 3.49453L18.375 2.625L17.5055 0.307617C17.4357 0.123047 17.2594 0 17.0625 0C16.8656 0 16.6893 0.123047 16.6195 0.307617L15.75 2.625L13.4326 3.49453ZM8.4123 3.00645C8.30566 2.77266 8.07188 2.625 7.81758 2.625C7.56328 2.625 7.32949 2.77266 7.22285 3.00645L5.05723 7.68223L0.381445 9.84375C0.147656 9.95039 0 10.1842 0 10.4426C0 10.701 0.147656 10.9307 0.381445 11.0373L5.06133 13.1988L7.21875 17.8746C7.32539 18.1084 7.55918 18.2561 7.81348 18.2561C8.06777 18.2561 8.30156 18.1084 8.4082 17.8746L10.5697 13.1947L15.2496 11.0332C15.4834 10.9266 15.6311 10.6928 15.6311 10.4385C15.6311 10.1842 15.4834 9.95039 15.2496 9.84375L10.5738 7.68633L8.4123 3.00645ZM15.75 15.75L13.4326 16.6195C13.248 16.6893 13.125 16.8656 13.125 17.0625C13.125 17.2594 13.248 17.4357 13.4326 17.5055L15.75 18.375L16.6195 20.6924C16.6893 20.877 16.8656 21 17.0625 21C17.2594 21 17.4357 20.877 17.5055 20.6924L18.375 18.375L20.6924 17.5055C20.877 17.4357 21 17.2594 21 17.0625C21 16.8656 20.877 16.6893 20.6924 16.6195L18.375 15.75L17.5055 13.4326C17.4357 13.248 17.2594 13.125 17.0625 13.125C16.8656 13.125 16.6893 13.248 16.6195 13.4326L15.75 15.75Z" fill="white"/>
                        </svg>
                    </div>
                    <div class="d-flex ai-messages__assistant" data-role="ai-reply">
                        ${message}
                    </div>
                </div>`;
        ScrollMessageContainer();
    }
    function ScrollMessageContainer() {
        // Scroll to the bottom
        //aiMessages.scrollTop = aiMessages.scrollHeight;
        document.documentElement.scrollTop = document.documentElement.scrollHeight;
    }
}
