'use strict';

import DataTransport from '@/helpers/data-transport';
import LoadingPage from '@/helpers/loading-page';

export default class ServerStatus {
    transport = new DataTransport();
    loadingPage = new LoadingPage();
    gameServerId = 0;
    showLog = false;
    currentLoadingMsg = "Loading Data";

    constructor(gameServerId, showLog) {
        this.gameServerId = gameServerId;
        this.showLog = showLog;

        document.querySelectorAll('[data-role="server-restart"]').forEach(element => {
            element.addEventListener('click', () => {
                this.restartServer();
            });
        });

        document.querySelectorAll('[data-role="server-shutdown"]').forEach(element => {
            element.addEventListener('click', () => {
                this.shutdownServer();
            });
        });

        this.setServerStatus("Connecting...");
        this.GetStatus();
    }

    updateServerStatus(newStatus) {
        // Create a new CustomEvent with the updated serverStatus
        const statusUpdate = new CustomEvent('statusUpdateEvent', {
            detail: newStatus
        });

        // Dispatch the event
        document.dispatchEvent(statusUpdate);
    }

    async GetStatus() {
        await this.transport.GetResponse(`/MyServers?exec=GetStatus&gameServerId=${this.gameServerId}&showLog=${this.showLog}`, { })
            .then(data => {
                this.updateServerStatus(data);

                //console.log(data);

                if (!data.error) {
                    if (data.MyServerStatus.QueueInstallation || !data.MyServerStatus.Installed) {
                        this.hideServerControls(data.MyServerStatus.Status, true);
                        this.setServerStatus("Installing Server", true);
                    } else if (data.MyServerStatus.QueueBoot || data.MyServerStatus.QueueShutdown || data.MyServerStatus.RunningTask) {
                        if (data.MyServerStatus.QueueBoot) {
                            this.setServerStatus("Rebooting", true);
                        } else if (data.MyServerStatus.QueueShutdown) {
                            this.setServerStatus("Shutting down", true);
                        } else {
                            this.setServerStatus(data.MyServerStatus.Status, true);
                        }
                        //console.log(data.MyServerStatus.Status);
                        this.hideServerControls(data.MyServerStatus.Status);
                    } else {
                        this.showServerControls();
                        this.setServerStatus(data.MyServerStatus.Status);
                    }
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });

        await new Promise(r => setTimeout(r, 5000));

        await this.GetStatus();
    }

    showServerControls() {
        document.querySelectorAll('[data-role="server-restart"]').forEach(element => {
            element.classList.remove("d-none");
        });
        document.querySelectorAll('[data-role="server-shutdown"]').forEach(element => {
            element.classList.remove("d-none");
        });
        this.loadingPage.HideLoading();
    }

    hideServerControls(msg="", overlay = false) {
        document.querySelectorAll('[data-role="server-restart"]').forEach(element => {
            element.classList.add("d-none");
        });
        document.querySelectorAll('[data-role="server-shutdown"]').forEach(element => {
            element.classList.add("d-none");
        });
        if (overlay) {
            this.currentLoadingMsg = msg;
            this.loadingPage.ShowLoading(this.currentLoadingMsg);
        }
    }

    setServerStatus(status = "", spinner = false) {
        const serverStatus = document.querySelectorAll('[data-role="server-status"]');
        const serverStatusIcon = status === 'Running' ? 'server-status-icon --on' : 'server-status-icon --off';
        if (serverStatus) {
            serverStatus.forEach((e) => {
                let spin = "";
                if (spinner)
                    spin = '<span class="spinner-border spinner-border-sm ml-s" role="status"></span>';
                e.innerHTML = `<span class="mr-s">Server Status:</span> <span class="${serverStatusIcon}"></span>${status} ${spin}`;
            })
        }
    }

    restartServer() {
        this.hideServerControls();
        this.setServerStatus("Rebooting", true);
        this.transport.GetRawResponse(`/MyServers?exec=RestartServer&gameServerId=${this.gameServerId}`, {})
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            })
            .finally(() => {

            });
    }

    shutdownServer() {
        this.hideServerControls("");
        this.setServerStatus("Shutting down", true);
        this.transport.GetRawResponse(`/MyServers?exec=ShutdownServer&gameServerId=${this.gameServerId}`, {})
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            })
            .finally(() => {

            });
    }

    async getServerConfig() {
        try {
            const data = await this.transport.GetResponse(`/MyServers?exec=GetConfig&gameServerId=${this.gameServerId}`, { });
            if (!data.error) {
                return data;
            } else {
                throw new Error(data.error); // Optionally handle error cases explicitly
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            throw error; // Re-throw the error if you want the caller to handle it
        }
    }
}
